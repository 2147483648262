<template>
  <div>
        <van-nav-bar
            title="更换头像"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <van-cell-group  >
            <van-cell  title="头像" is-link to="/SetAvatar" center >
                <!-- <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1">
                    <img :src="avatarUrl" style="width:100%; height:50px; width:50px; border-radius:5px; margin-top:5px" alt="">
                </van-uploader > -->
                <img :src="avatarUrl" @click="editAvatar" style="width:100%; height:50px; width:50px; border-radius:5px; margin-top:5px" alt="" >
                <H5Cropper
                    
                    ref="h5Cropper"
                    @getblobData="getblobData"
                    @getFile="getFile"
                />
            </van-cell>
         
        </van-cell-group>
        <van-overlay :show="isLock" @click="show = false">
            <div class="wrapper" @click.stop>
                <van-loading v-show="isLock" type="spinner" color="white" text-color="white" size="45px" vertical style="position:absolute; margin: 0; top: 270px; left: 0; right: 0; margin: auto; ">上传中...</van-loading>
            </div>
        </van-overlay>
  </div>
</template>

<script>
import { Toast } from 'vant'
import H5Cropper  from 'vue-cropper-h5'
export default {
    components:{
        H5Cropper
    },
    data() {
        return {
            avatarUrl: '',
            fileList:[],
            isLock:false
        }
    },
    methods: {
        onClickLeft() {
            this.$router.replace('/Edit')
        },

        editAvatar(){
            document.querySelector('input.upbtn').click();
        },
        getFile(file){
            // console.log(file)
        },

        getblobData(blob){
            this.isLock = true
           
            const forms = new FormData();
            forms.append("image", blob);
            this.$axios.post('/user/editAvatar',forms, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            }).then(
                response=>{
                    console.log("ss",response)
                    this.editposteravatar(response.data.data)
                    this.editInfo(response.data.data);
                }
            )
        },

        editposteravatar(val){
            const that = this
            that.$axios.post('/user/editPosterAvatar',{
                id: that.$store.getters.getUser.id,
                username: that.$store.getters.getUser.username,
                avatar: val,
                nickname: that.$store.getters.getUser.nickname
            }).then(
                res => {
                    console.log(res.data.data)
                }
            )
        },

        editInfo(val){
            const _this = this
            _this.$axios.post('/user/editInfo',{
                id: _this.$store.getters.getUser.id,
                username: _this.$store.getters.getUser.username,
                avatar: val,
                nickname: _this.$store.getters.getUser.nickname
            }).then(
            response => {
                
                const userInfo = response.data.data
                _this.$store.commit("SET_USERINFO", userInfo)
                Toast("操作成功(❁´◡`❁)")
                this.avatarUrl = val
                this.isLock = false
                
            }
            )

        }

        // afterRead(file) {
        //     //在这块创建FormData对象
        //     // FormData 对象的使用：
        //     // 1.用一些键值对来模拟一系列表单控件：即把form中所有表单元素的name与value组装成一个queryString
        //     // 2. 异步上传二进制文件。
        //     //上传图片需要转换二进制这里要用到FormData
        //     const that = this
            
        //     const forms = new FormData();
        //     forms.append("image", file.file);
        //     // console.log(file.file)
        //     this.$axios.post('/user/editAvatar',forms, {
        //         headers: {
        //             "content-type": "multipart/form-data"
        //         }
        //     }).then(
        //         response=>{
        //             console.log("ddwaa",response)
        //             that.avatarUrl = response.data.data
        //             this.editInfo(response.data.data);
        //         }
        //     )

        // },

    },
    created(){

        this.avatarUrl = this.$store.getters.getUser.avatar
        this.userId = this.$store.getters.getUser.id
    }

    
}
</script>

<style>
    .cropper {
        
        width: 0;
        height: 0;
        line-height: 80px;
        position: absolute;
        top: 0;
        left: 0;
    }

    div.btndiv {
        position: fixed;
        bottom: 10vh;
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }

    .upbtn {
        position: absolute;
    }
</style>